import { defineStore } from 'pinia'
import type { HODLPool, HODLPoolAccount } from '@deorderbook/sdk'
import { getHodlPoolList, getUserHODLPoolList } from '@deorderbook/sdk'
import { sellTokenHODLAddress, uHODLAddress } from '@deorderbook/contracts'
import { isCheckNFT, NFTAddress } from '@deorderbook/sdk/ethereum/hodl'
import { getHODLPoolRewardAmount } from '@deorderbook/sdk/ethereum/hodl_pool_rewarder'
import { Big } from 'big.js'
import { gql } from '@urql/core'
import { onWalletReady } from '@base/composables/onWalletReady'
import { useTokensStore } from '@base/store/tokens'
import type { ActivePoolResponse } from '@base/types/sniperPools'
import { formatPercentage, getPercentageChange } from '@base/utils/number'
import type {
  Hodl,
  FormattedHODLPool,
  FormattedUserHODLPool,
  HodlFees,
} from '@base/types/hodl'

const GET_ACTIVE_HODL_POOLS_TVL_QUERY = gql`
  query stats_tvl_by_active_pool {
    stats_tvl_by_active_pool(where: { type: { _like: "%HODLPool" } }) {
      type
      expiry
      pool_id
      strike
      underlying
      underlying_in_pool
      underlying_in_pool_usd
      underlying_in_pool_usd_24hr_ago
      total_value_locked
      total_value_locked_24hr_ago
      collateral_in_pool
      collateral_in_pool_usd
      collateral_in_pool_usd_24hr_ago
    }
  }
`

const HODL_POOL_NAMES: Record<string, Hodl> = {
  UHODLPool: 'uHODL',
  SELLTOKENHODLPool: 'sellTokenHODL',
}

/**
 * @name useHODLStore
 * @description HODL store
 */
export const useHODLStore = defineStore('hodl', () => {
  const { client } = useBackendClient()
  const { divDecimals } = useTokens()
  const { tokenBalance, address } = toRefs(useWalletStore())
  const { tokenDOB, tokenUHODL, tokenSellTokenHODL } = toRefs(useTokensStore())
  const { actionRefreshTokensPrice } = useTokensStore()

  const {
    fetchState: fetchIsCheckNFT,
    refreshAllState: refreshAllIsCheckNFT,
    getCaches: getIsCheckNFTCaches,
  } = useAsyncCache((...args: Parameters<typeof isCheckNFT>) => {
    return isCheckNFT(...args)
  }, false)

  const {
    fetchState: fetchNFTAddress,
    refreshAllState: refreshAllNFTAddress,
    getCaches: getNFTAddressCaches,
  } = useAsyncCache((...args: Parameters<typeof NFTAddress>) => {
    return NFTAddress(...args)
  }, '')

  const uHODL = ref<FormattedHODLPool>(defaultUHODL)
  const sellTokenHODL = ref<FormattedHODLPool>(defaultSellTokenHODL)
  const userUHODL = ref<FormattedUserHODLPool>(defaultUserUHODL)
  const userSellTokenHODL = ref<FormattedUserHODLPool>(defaultUserSellTokenHODL)
  const loading = ref(false)
  const loadingFees = ref(false)
  const loadingUserHodlPools = ref(false)
  const error = ref<Error | null>(null)

  const hodlPools = computed(() => {
    return [uHODL.value, sellTokenHODL.value]
  })
  const userHODLPools = computed(() => {
    return [userUHODL.value, userSellTokenHODL.value]
  })

  const hodlFees = ref<{ [key in Hodl]: HodlFees }>(defaultHodlFees)

  const userHodlPoolsState = ref<HODLPoolAccount[]>([])

  async function formatUserHodlPools() {
    for (const targetPool of hodlPools.value) {
      let one = userHodlPoolsState?.value.find((pool) => {
        return targetPool.id === pool.poolId
      })
      if (one === undefined || userHodlPoolsState?.value.length === 0) {
        one = {
          id: `${targetPool.id}_${address.value}`,
          poolId: targetPool.id,
          account: address.value,
          staked: '0',
        }
      }
      if (!one.poolId) return
      const reward = await getHODLPoolRewardAmount(one.poolId).catch((err) => {
        console.error(err)
        return 0
      })
      const hodlToken =
        targetPool.token === uHODLAddress.toLowerCase()
          ? 'uHODL'
          : 'sellTokenHODL'
      const hodlPrice =
        hodlToken === 'uHODL'
          ? tokenUHODL.value.priceUSD
          : tokenSellTokenHODL.value.priceUSD
      const userBalance =
        targetPool.token === uHODLAddress.toLowerCase()
          ? tokenBalance.value.balanceUHODL
          : tokenBalance.value.balanceSellTokenHODL
      const tmp: FormattedUserHODLPool = {
        ...one,
        token: targetPool.token,
        type: hodlToken,
        stakedUSD: Big(divDecimals(one.staked, hodlToken).value)
          .mul(hodlPrice)
          .toFixed(),
        balance: userBalance,
        balanceUSD: Big(divDecimals(userBalance, hodlToken).value)
          .mul(hodlPrice)
          .toFixed(),

        apr: targetPool.apr ?? '0',
        aprString: targetPool.aprString,
        rewardDOBAmount: reward?.toString() || '0',
        rewardDOBAmountUSD: Big(
          divDecimals(reward?.toString() || '0', 'DOB').value,
        )
          .mul(tokenDOB.value.priceUSD)
          .toFixed(),
      }
      if (hodlToken === 'uHODL') {
        userUHODL.value = tmp
      } else {
        userSellTokenHODL.value = tmp
      }
    }
  }

  watch(
    [hodlPools, address],
    async () => {
      if (!address.value) return
      try {
        loadingUserHodlPools.value = true
        userHodlPoolsState.value = await getUserHODLPoolList(address.value)
        await formatUserHodlPools()
      } catch (error) {
        console.error(error)
      } finally {
        loadingUserHodlPools.value = false
      }
    },
    {
      deep: true,
      immediate: true,
    },
  )

  // when uHODL or bHODL balance change ,should update userUHODL and userBHODL
  watch(
    [tokenBalance, tokenUHODL, tokenSellTokenHODL],
    () => {
      userUHODL.value.balance = tokenBalance.value.balanceUHODL
      userUHODL.value.balanceUSD = Big(
        divDecimals(userUHODL.value.balance, 'uHODL').value,
      )
        .mul(tokenUHODL.value.priceUSD)
        .toFixed()

      userSellTokenHODL.value.balance = tokenBalance.value.balanceSellTokenHODL
      userSellTokenHODL.value.balanceUSD = Big(
        divDecimals(userSellTokenHODL.value.balance, 'sellTokenHODL').value,
      )
        .mul(tokenSellTokenHODL.value.priceUSD)
        .toFixed()
    },
    { deep: true },
  )

  function formatHodlPools(
    hodlPoolList: HODLPool[],
    hodlPoolsWithTvl: ActivePoolResponse[],
  ) {
    for (const pool of hodlPoolList) {
      const apr = calculateHodlApr(pool)
      const token =
        pool.token === uHODLAddress.toLowerCase() ? 'uHODL' : 'sellTokenHODL'
      const tokenPrice =
        token === 'uHODL'
          ? tokenUHODL.value.priceUSD
          : tokenSellTokenHODL.value.priceUSD
      const poolWithTvl = hodlPoolsWithTvl.find(
        (p) => HODL_POOL_NAMES[p.type] === token && p.underlying === token,
      )
      const stakedAmountUSD = Big(divDecimals(pool.stakedAmount, token).value)
        .mul(tokenPrice)
        .toFixed()
      const tmp: FormattedHODLPool = {
        ...pool,
        type: token,
        name: token,
        stakedAmountUSD,
        apr,
        aprString: formatPercentage(apr),
        underlying: poolWithTvl
          ? {
              token: poolWithTvl?.underlying,
              value:
                poolWithTvl?.underlying && poolWithTvl?.underlying_in_pool
                  ? divDecimals(
                      poolWithTvl?.underlying_in_pool,
                      poolWithTvl?.underlying,
                    ).value
                  : '0',
            }
          : undefined,
        tvl: poolWithTvl?.total_value_locked ?? Number(stakedAmountUSD),
        tvlChange: poolWithTvl
          ? getPercentageChange(
              poolWithTvl?.total_value_locked_24h_ago || 0,
              poolWithTvl?.total_value_locked || 0,
            )
          : undefined,
      }
      if (token === 'uHODL') {
        uHODL.value = tmp
      } else {
        sellTokenHODL.value = tmp
      }
    }
  }

  async function actionRefreshHODLPools(force = false) {
    if (!force && loading.value) {
      return
    }
    loading.value = true
    try {
      const [hodlPoolList, hodlPoolsWithTvlResponse] = await Promise.all([
        getHodlPoolList(),
        client.value.query(GET_ACTIVE_HODL_POOLS_TVL_QUERY, {}).toPromise(),
        actionRefreshTokensPrice(true), // fetch token prices because they are used to calculate vAPR and are not updated in the background
      ])
      const hodlPoolsWithTvl = (hodlPoolsWithTvlResponse?.data
        ?.stats_tvl_by_active_pool ?? []) as ActivePoolResponse[]
      formatHodlPools(hodlPoolList, hodlPoolsWithTvl)
      error.value = null
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err)
      if (err?.body) {
        error.value = new Error(JSON.parse(err.body).error.message, {
          cause: err,
        })
      } else {
        error.value = new Error(err?.message ?? err, {
          cause: err,
        })
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * Calculate APR for HODL pool
   * Formula: [(rewardPerBlock * BLOCK_PER_YEAR * DOB price) / (stakedAmount in USD)] * 100 %
   * @param pool
   * @returns
   */
  function calculateHodlApr(pool: HODLPool): string {
    const rewardUSD = Big(divDecimals(pool.rewardPerBlock, 'DOB').value)
      .times(BLOCK_PER_YEAR)
      .times(tokenDOB.value.priceUSD)

    const stakedUSD = Big(
      divDecimals(
        pool.stakedAmount,
        pool.token === uHODLAddress.toLowerCase() ? 'uHODL' : 'sellTokenHODL',
      ).value,
    ).times(
      pool.token === uHODLAddress.toLowerCase()
        ? tokenUHODL.value.priceUSD
        : tokenSellTokenHODL.value.priceUSD,
    )

    let apr = Big(100000) // DUMMY INITIAL VALUE to have >10,000% APR

    if (stakedUSD.gt(0)) {
      apr = rewardUSD.div(stakedUSD).mul(100)
    }

    return apr.toFixed()
  }

  async function actionFetchHodlFees() {
    loadingFees.value = true

    const { data } = await client.value
      .query(GET_HODL_FEES_QUERY, {})
      .toPromise()
    const _hodlFees = (data?.stats_fee_by_token ?? []) as HodlFees[]

    hodlFees.value = {
      uHODL:
        _hodlFees?.find((i: HodlFees) => i.token === 'uHODL') ??
        hodlFees.value.uHODL,
      sellTokenHODL:
        _hodlFees?.find((i: HodlFees) => i.token === 'sellTokenHODL') ??
        hodlFees.value.sellTokenHODL,
    }

    loadingFees.value = false
  }

  onWalletReady(
    () => {
      Promise.all([actionRefreshHODLPools(), actionFetchHodlFees()]).then()
    },
    {
      status: 'setup',
    },
  )

  return {
    fetchIsCheckNFT,
    refreshAllIsCheckNFT,
    getIsCheckNFTCaches,
    fetchNFTAddress,
    refreshAllNFTAddress,
    getNFTAddressCaches,
    uHODL,
    sellTokenHODL,
    userUHODL,
    userSellTokenHODL,
    hodlPools,
    userHODLPools,
    actionRefreshHODLPools,
    loadingFees,
    hodlFees,
    actionFetchHodlFees,
    loading: computed(
      () => loading.value || loadingFees.value || loadingUserHodlPools.value,
    ),
    error,
  }
})

const defaultUHODL = {
  id: '',
  token: uHODLAddress.toLowerCase(),
  option: '',
  startBlock: '',
  endBlock: '',
  rewardPerBlock: '0',
  stakedAmount: '0',
  type: 'uHODL',
  name: 'uHODL',
  apr: '0',
  aprString: '0',
  stakedAmountUSD: '0',
}
const defaultSellTokenHODL = {
  id: '',
  token: sellTokenHODLAddress.toLowerCase(),
  option: '',
  startBlock: '',
  endBlock: '',
  rewardPerBlock: '0',
  stakedAmount: '0',
  type: 'sellTokenHODL',
  name: 'sellTokenHODL',
  apr: '0',
  aprString: formatPercentage('0'),
  stakedAmountUSD: '0',
}
const defaultUserUHODL = {
  id: '',
  poolId: '',
  account: '',
  staked: '0',
  token: '',
  type: 'uHODL',
  stakedUSD: '0',
  balance: '0',
  balanceUSD: '0',
  rewardDOBAmount: '0',
  rewardDOBAmountUSD: '0',
  apr: '0',
  aprString: formatPercentage('0'),
}
const defaultUserSellTokenHODL = {
  id: '',
  poolId: '',
  account: '',
  staked: '0',
  token: '',
  type: 'sellTokenHODL',
  stakedUSD: '0',
  balance: '0',
  balanceUSD: '0',
  rewardDOBAmount: '0',
  rewardDOBAmountUSD: '0',
  apr: '0',
  aprString: formatPercentage('0'),
}

const defaultHodlFees: { [key in Hodl]: HodlFees } = {
  uHODL: {
    fees: {
      past_24h: {
        amount: 0,
        amount_usd: 0,
      },
      past_7d: {
        amount: 0,
        amount_usd: 0,
      },
      past_30d: {
        amount: 0,
        amount_usd: 0,
      },
    },
    token: 'uHODL',
  },
  sellTokenHODL: {
    fees: {
      past_24h: {
        amount: 0,
        amount_usd: 0,
      },
      past_7d: {
        amount: 0,
        amount_usd: 0,
      },
      past_30d: {
        amount: 0,
        amount_usd: 0,
      },
    },
    token: 'sellTokenHODL',
  },
}

const GET_HODL_FEES_QUERY = gql`
  query getHodlFees {
    stats_fee_by_token(
      order_by: { timestamp: desc }
      where: { token: { _like: "%HODL" } }
      limit: 2
    ) {
      fees
      token
    }
  }
`
